import { BaseComponent } from "@abstract/BaseComponent";
import { Component, QueryList, ViewChildren } from "@angular/core";
import { Const } from "@const/Const";
import { ShipmentQueueShipment, ShipmentQueueSummary } from "@wearewarp/types/rest-api/admin";
import { PaginationData } from '@app/model/PaginationData';
import { ShipmentQueueListShipments } from "./list-shipments";

interface TabData {
  key: string, name: string, apiPath: string, data: PaginationData<ShipmentQueueShipment>, actions?: any[]
}

@Component({
  selector: '[shipment-queue]',
  templateUrl: './view.html',
  styleUrls: ['./style.scss']
})
export class ShipmentQueue extends BaseComponent {
  readonly tabs: TabData[] = [
    {
      key: 'needRouting',
      name: 'Need Routing',
      apiPath: 'need_routing',
      data: new PaginationData(),
    },
    {
      key: 'needCreateLeg',
      name: 'Need Create Leg',
      apiPath: 'need_legs',
      data: new PaginationData(),
    },
    {
      key: 'needAppointment',
      name: 'Need Appointment',
      apiPath: 'need_appt',
      data: new PaginationData(),
    },
    {
      key: 'needReview',
      name: 'Orderful Order Need Review',
      apiPath: 'need_review',
      data: new PaginationData(),
    },
    {
      key: 'shipmentNeedReview',
      name: 'Shipment Need Review',
      apiPath: 'shipment_need_review',
      data: new PaginationData(),
    },
  ];

  tabIndex = -1;
  get currentTab(): TabData {
    return this.tabs[this.tabIndex];
  }
  private sumData: ShipmentQueueSummary;

  @ViewChildren('listShipment') listShipment: QueryList<ShipmentQueueListShipments>;

  constructor() {
    super();
    this.getSummary();
  }

  // Tham khảo định nghĩa màu trong style.scss
  getColorIndex(index: number): number {
    const colorCount = 4;
    return index % colorCount + 1;
  }

  getTabLabel(tab: TabData): string {
    let key = tab.key;
    let count = this.sumData?.[key] ?? 0;
    return `${tab.name} (${count})`;
  }

  countForTab(tab: TabData): number {
    let key = tab.key;
    return this.sumData?.[key] ?? 0;
  }

  protected handleNavigationEnd(url: string, prevQueryParam: any): void {
    const {tab} = this.queryParams;
    if (!tab || !this.isTabValid(tab)) {
      return this.routeWithQueryUrl({tab: this.tabs[0].key});
    }
    let index = this.tabKeyToIndex(tab);
    if (this.tabIndex != index) {
      this.tabIndex = index;
    }
  }

  private isTabValid(key: string): boolean {
    return this.tabs.filter(it => it.key == key).length == 1;
  }

  private tabKeyToIndex(key: string): number {
    for (let i = 0; i < this.tabs.length; i++) {
      if (this.tabs[i].key == key) {
        return i;
      }
    }
    return 0;
  }

  private tabIndexToKey(index: number): string {
    if (index >= 0 && index < this.tabs.length) {
      return this.tabs[index].key;
    }
    return this.tabs[0].key;
  }

  onTabChange(event) {
    this.routeWithQueryUrl({tab: this.tabIndexToKey(event.index), page: 1}, true);
  }

  onSumDataUpdated(tab: TabData, data: {total: number}) {
    if (!this.sumData) {
      return;
    }
    this.sumData[tab.key] = data.total;
  }

  private getSummary() {
    let url = Const.APIURI_SHIPMENT_QUEUE('summary');
    this.api.GET(url).subscribe(
      resp => {
        this.sumData = resp.data;
      }, err => {
        this.showErr(err);
      }
    );
  }

}