import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { ShipmentQueueFilterNeedRouting } from './need-routing';
import { ModuleFilterLayout } from '@app/admin/components/filter-layout/module';
import { SelectBySearchingModule } from '@libs/select-client/module';
import { ModuleFilterStates } from '@wearewarp/ng-antd';
import { ShipmentQueueFilterNeedAppointment } from './need-appointment';
import { NzInputModule } from 'ng-zorro-antd/input';
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NzFormModule,
    NzIconModule,
    NzSelectModule,
    NzDatePickerModule,
    ModuleFilterLayout,
    SelectBySearchingModule,
    ModuleFilterStates,
    NzInputModule,
  ],
  declarations: [
    ShipmentQueueFilterNeedRouting,
    ShipmentQueueFilterNeedAppointment
  ],
  exports: [
    ShipmentQueueFilterNeedRouting,
    ShipmentQueueFilterNeedAppointment
  ]
})
export class ShipmentQueueFilterModule {}