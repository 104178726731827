
import { Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import { Const } from "@const/Const";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { DateUtil } from "@services/date-utils";
import { Utils } from "@services/utils";
import { Const as WarpConst } from "@wearewarp/universal-libs";

@Component({
  selector: "note-dialog",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})
export class NotesDialog extends BaseFormDialog1 implements OnInit {

  private _locationId: string;

  @Input() get locationId() {
    return this._locationId
  };
  set locationId(value) {
    this._locationId = value;
    if (value) {
      this.fetchData();
    }
  }
  @Input() visible = true;
  @Output() visibleChange = new EventEmitter<boolean>();
  newNoteMsg: string;
  edittingId: number = -1;
  items: any = {};
  isLoading: boolean = true;
  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  onDataChange() {
    this.fetchData();
  }

  formatData(data) {
    let items = {};
    for (let note of data) {
      if (Utils.isArrayNotEmpty(note?.imageUploadFilesArr)) {
        for (let item of note.imageUploadFilesArr) {
          item.imgUrl = this.attachedFileUrl(item);
        }
      }
      let date = note.insert?.when;
      note['time'] = DateUtil.dateToString(date, Const.FORMAT_GUI_DATETIME)
      date = DateUtil.dateToString(date, Const.FORMAT_GUI_DATE);
      if (!items[date]) items[date] = [];
      items[date].push(note);
    }
    return items;
  }

  private getUrlConvensation(subjectId: string) {
    return `${Const.APIURI_CONVERSATIONS}/?subjectId=${subjectId}&subjectType=${WarpConst.ConversationSubjectType.deliveryInfo}&type=${WarpConst.ConversationType.book_appointment}`
  }

  fetchData() {
    this.startProgress();
    this.isLoading = true;
    this.items = {};
    this.api.GET(this.getUrlConvensation(this.locationId)).subscribe(
      (resp) => {
        const listData = resp?.data?.list_data || [];
        this.items = this.formatData(listData);
        this.stopProgress();
        this.isLoading = false;
      },
      (err) => {
        this.showErr(err);
        this.stopProgress();
        this.isLoading = false;
      }
    );
  }

  close = () => {
    this.newNoteMsg = "";
    this.visibleChange.emit(false);
  };

  onAdd = () => {
    if (!this.newNoteMsg) return;
    //call API for add new note
    this.startProgress();
    this.isLoading = true;
    let formData = new FormData();
    const jsonData = {
      'content': this.newNoteMsg
    }
    formData.append("params", JSON.stringify(jsonData));
    this.api
      .postFormData(this.getUrlConvensation(this.locationId), formData)
      .subscribe(
        (resp) => {
          // this.showSuccess("Your Note has been added successfully.");
          this.newNoteMsg = "";
          this.stopProgress();
          this.isLoading = false;
          this.fetchData();
        },
        (err) => {
          this.showErr(err);
          this.newNoteMsg = "";
          this.stopProgress();
          this.isLoading = false;
        }
      );
  };

  onEdit = (item) => {
    this.newNoteMsg = item.content;
    this.edittingId = item.id;
  };

  onSubmitEdit = () => {
    this.startProgress();
    this.isLoading = true;
    this.api
      .PUT(`${Const.APIURI_CONVERSATIONS}/${this.edittingId}`,
        {content: this.newNoteMsg, subjectId: this.locationId})
      .subscribe(
        (resp) => {
          this.isLoading = false;
          this.showSuccess("Your Note has been edited successfully.");
          this.onCancelEdit();
          this.fetchData();
          this.stopProgress();
        },
        (err) => {
          this.showErr(err);
          this.onCancelEdit();
          this.stopProgress();
          this.isLoading = false;
        }
      );
  };

  onCancelEdit = () => {
    this.edittingId = -1;
    this.newNoteMsg = "";
  };

  getDateItems() {
    return Object.keys(this.items);
  }

  get shouldShowBtnAddNote() {
    if (this.isLoading) return false;
    if (!this.newNoteMsg) return false;
    return true;
  }

  get shouldShowBtnCancel() {
    if (this.isLoading) return false;
    if (this.edittingId != -1 || this.newNoteMsg) return true;
    return false;
  }

  getLabelBtnAdd() {
    if (this.edittingId != -1) return 'Update';
    return 'Add';
  }
  onClose() {
    this.closeDialog();
  }
}
