import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ShipmentQueue } from '.';


const shipmentQueueRoutes: Routes = [
  {
    path: '', component: ShipmentQueue, data: { title: 'Shipment Queue - WARP Admin' }, children: [
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(shipmentQueueRoutes)],
  exports: [RouterModule]
})
export class ShipmentQueueRoutingModule { }
