import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { BaseList } from "@app/admin/base/list";
import { NavigationEndData } from "@app/interfaces";
import { Const } from "@const/Const";
import { BizUtil } from "@services/biz";
import { MasterData } from "@services/master.data";
import { BookAppointment, FormDataBookAppointment, FormDataUpdateTimeWindows, ModalHelper, UpdateTimeWindows } from "@wearewarp/ng-antd";
import { StringULID } from "@wearewarp/types";
import { AddressUS } from "@wearewarp/types/data-model";
import { ShipmentQueueDeliveryInfo } from "@wearewarp/types/rest-api/admin";
import { ShipmentQueueShipment } from "./interface";
import { DialogService } from "@dialogs/dialog.service";
import { OrderfulInfoDialog } from "@app/admin/shipments/orderful-info";
import { ShipmentEntryDetail } from "@app/admin/shipment-entry/detail";
import { TransitNetwork } from "@app/admin/transit-network";
import { NzDrawerService } from "ng-zorro-antd/drawer";
import { ShipmentQueueFilterNeedRouting } from "../filter/need-routing";
import { Utils } from "@services/utils";
import { PlanningService } from "@services/planning.service";
import { getFeatureFlags } from "@services/feature-flag.service";
import { UpdateShipmentReviewStatusForm } from "@app/admin/planning/update-review-status";

@Component({
  selector: '[shipment-queue-list-shipments]',
  templateUrl: './view.html',
  styleUrls: ['./style.scss']
})
export class ShipmentQueueListShipments extends BaseList<ShipmentQueueShipment> {
  @Input() apiPath: string;
  @Input() isVisible: boolean = false;
  @Output() sumDataUpdated = new EventEmitter<any>();

  @ViewChild('filter') filter: ShipmentQueueFilterNeedRouting;

  get isTabNeedRouting(): boolean { return this.apiPath == 'need_routing' }
  get isTabNeedReview(): boolean { return this.apiPath == 'need_review' }
  get isTabShipmentNeedReview(): boolean { return this.apiPath == 'shipment_need_review' }
  get isTabNeedLeg(): boolean { return this.apiPath == 'need_legs' }
  get isTabNeedAppointment(): boolean { return this.apiPath == 'need_appt' }

  get hasAction(): boolean { return this.isTabNeedReview || this.isTabNeedLeg || this.isTabShipmentNeedReview || (this.isTabNeedRouting && getFeatureFlags().isFlagSet('PLANNING_TOOL')) }
  get hasFilter(): boolean { return this.isTabNeedRouting }

  constructor(private modalHelper: ModalHelper, private nzDrawerService: NzDrawerService) {
    super();
    this.subscribeNavigationEnd();
  }

  getRouterLink(item: ShipmentQueueShipment) {
    if (!item.shipmentTransitType || item.shipmentTransitType == Const.ShipmentTransitType.none) {
      return [this.routeAdminOrderList, item.orderId];
    } else {
      return [this.routeAdminOrderList, item.parentId, 'tracking-items']
    }
  }

  isOrderful(item: ShipmentQueueShipment) {
    return item.order?.source === Const.thirdPartyOrderSource.orderful;
  }

  clickOrderful(item: ShipmentQueueShipment) {
    this.openOrderfulDialog(item);
  }

  // Gọi trong hàm constructor
  protected subscribeNavigationEnd() {
    this.subscription.add(this.appComponent.subscribeNavigationEnd((e: NavigationEndData) => {
      if (this.isVisible) {
        this.onNavigationEnd(e.current.url);
      }
    }));
    if (this.appComponent.getCurrentNavigationUrlData()) {
      // Đợi kết thúc hàm constructor rồi mới thực hiện
      setTimeout(() => this.onNavigationEnd(this.appComponent.createNavigationEndData().current.url), 0);
    }
  }

  protected handleNavigationEnd(url: string, prevQueryParam: any): void {
    if (this.hasFilter) {
      // Trong 4 tab chỉ có 1 tab xử lý filter nên mỗi khii chuyển tab qua lại thì cần đồng bộ filter trên form và filter trên query url
      let json = this.filter.getFilterData();
      if (Utils.isObjectNotEmpty(json)) {
        let jsonStr = JSON.stringify(json);
        if (jsonStr != this.queryParams.filter) {
          return this.routeWithQueryUrl({filter: jsonStr});
        }
      }
    }
    super.handleNavigationEnd(url, prevQueryParam);
  }

  protected getApiUrl(): string {
    return Const.APIURI_SHIPMENT_QUEUE(this.apiPath);
  }

  protected prepareParamGetList() {
    let params = super.prepareParamGetList();
    if (params.filter && !this.hasFilter) {
      params.filter = undefined;
    }
    return params;
  }

  protected getDataDone(resp: any): void {
    this.processData(resp);
    super.getDataDone(resp);
  }

  private processData(resp) {
    let total: number = resp.data.total ?? 0;
    this.sumDataUpdated.emit({total});
    if (!resp.data.list_data) {
      return;
    }
    for (let item of resp.data.list_data) {
      this.processItem(item);
    }
  }

  private processItem(item: ShipmentQueueShipment) {
    item.transitType = this.getTransitType(item);
    const pickInfo = BizUtil.getPickInfo(item);
    const dropInfo = BizUtil.getDropInfo(item);
    item.pickStreet = this.getStreet(pickInfo.addr);
    item.pickCity = this.getCityState(pickInfo.addr);
    item.pickAddr = this.getAddressText(pickInfo.addr);
    item.pickDt = BizUtil.getDeliveryInfoTime(pickInfo, {appointmentFlag: '\n(Appointment Scheduled)'});
    item.dropCity = this.getCityState(dropInfo.addr);
    item.dropStreet = this.getStreet(dropInfo.addr);
    item.dropAddr = this.getAddressText(dropInfo.addr);
    item.dropDt = BizUtil.getDeliveryInfoTime(dropInfo, {appointmentFlag: '\n(Appointment Scheduled)'});
    const shouldWarnPickAppt = pickInfo.requiresAppointment == true && !pickInfo.appointmentInfo?.from;
    const shouldWarnDropAppt = dropInfo.requiresAppointment == true && !dropInfo.appointmentInfo?.from;
    const shouldWarnPickWindow = !shouldWarnPickAppt && !item.pickDt && !pickInfo.windows?.[0]?.from;
    const shouldWarnDropWindow = !shouldWarnDropAppt && !item.dropDt && !dropInfo.windows?.[0]?.from;
    item.shouldWarnAppt = shouldWarnPickAppt || shouldWarnDropAppt;
    item.warnPickAppt = shouldWarnPickAppt ? 'Requires Appointment' : '';
    item.warnDropAppt = shouldWarnDropAppt ? 'Requires Appointment' : '';
    item.warnPickWindow = shouldWarnPickWindow ? 'No Time Windows' : '';
    item.warnDropWindow = shouldWarnDropWindow ? 'No Time Windows' : '';
    if (this.apiPath == 'need_legs') {
      item.actions = [
        {name: 'Transit Management', handler: this.openTransitManagement.bind(this)}
      ]
    } else if (this.apiPath == 'need_review') {
      let order = item.order;
      const isOrderful = order.source === Const.thirdPartyOrderSource.orderful;
      const needResponseOrderful = isOrderful && order.review == null;
      if (needResponseOrderful) {
        item.actions = [
          {name: 'Reponse To Orderful', handler: this.openOrderfulDialog.bind(this)}
        ]
      } else {
        item.actions = [
          {name: 'Review Order', handler: this.openOrderDetail.bind(this)}
        ]
      }
    } else if (this.apiPath === 'need_routing') {
      if (getFeatureFlags().isFlagSet('PLANNING_TOOL'))
        item.actions = [
          {name: 'Ready for routing', handler: this.openReadyForRoutingConfirmation.bind(this)}
        ]
    } else if (this.apiPath === 'shipment_need_review') {
      item.actions = [
        {name: 'Review', handler: this.openUpdateShipmentReviewStatus.bind(this)}
      ]
    }
  }

  private getTransitType(item: ShipmentQueueShipment) {
    if (!item.shipmentTransitType || item.shipmentTransitType == Const.ShipmentTransitType.none) {
      return '';
    }
    return item.shipmentTransitType;
  }

  private getCityState(addr: AddressUS) {
    return `${addr.city}, ${addr.state}`;
  }

  private getStreet(addr: AddressUS) {
    if (addr.street2) {
      return `${addr.street} ${addr.street2}`;
    } else {
      return addr.street;
    }
  }

  private getPick(item: ShipmentQueueShipment): ShipmentQueueDeliveryInfo {
    return item.deliveryInfos.filter(it => it.type == Const.TaskType.PICKUP)[0];
  }

  private getDrop(item: ShipmentQueueShipment): ShipmentQueueDeliveryInfo {
    return item.deliveryInfos.filter(it => it.type == Const.TaskType.DROPOFF)[0];
  }

  private updateDeliveryInfo(shipment: ShipmentQueueShipment, deliveryId: StringULID, data: FormDataUpdateTimeWindows | FormDataBookAppointment) {
    const url = Const.APIV2(`shipments/${shipment.id}/delivery-info/${deliveryId}`);
    return this.api.PUT(url, data);
  }

  private updateDeliveryWindow(shipment: ShipmentQueueShipment, delivery: ShipmentQueueDeliveryInfo) {
    let type = delivery.type == Const.TaskType.PICKUP ? 'Pickup' : 'Delivery';
    UpdateTimeWindows.openModal(this.modalHelper, {
      onSubmitError: err => this.showErr(err),
      onSubmitSucceeded: resp => this.onBtnRefresh(),
      nzTitle: `Update ${type} Time Windows for ${this.showShipmentOnlyCode(shipment)}`,
      nzComponentParams: {
        timezone: delivery.addr?.metadata?.timeZoneStandard,
        reasonCodes: MasterData.getChangeDateTimeReasons(),
        model: {
          windows: delivery.windows,
          reasonCodeId: delivery.reasonCodeId
        },
        submit: (data: FormDataUpdateTimeWindows) => this.updateDeliveryInfo(shipment, delivery.id, data)
      }
    });
  }

  private updateAppt(shipment: ShipmentQueueShipment, delivery: ShipmentQueueDeliveryInfo) {
    let type = delivery.type == Const.TaskType.PICKUP ? 'Pickup' : 'Delivery';
    const appointmentInfo = delivery?.appointmentInfo || {};
    BookAppointment.openModal(this.modalHelper, {
      onSubmitError: err => this.showErr(err),
      onSubmitSucceeded: resp => this.onBtnRefresh(),
      nzTitle: `Update ${type} Appointment for ${this.showShipmentOnlyCode(shipment)}`,
      nzComponentParams: {
        timezone: delivery.addr?.metadata?.timeZoneStandard,
        reasonCodes: MasterData.getChangeDateTimeReasons(),
        model: {
          appointmentInfo,
          reasonCodeId: delivery.reasonCodeId,
        },
        submit: (data: FormDataBookAppointment) => this.updateDeliveryInfo(shipment, delivery.id, data)
      },
    });
  }

  onBtnUpdatePickAppt(item: ShipmentQueueShipment) {
    this.updateAppt(item, this.getPick(item));

  }

  onBtnUpdateDropAppt(item: ShipmentQueueShipment) {
    this.updateAppt(item, this.getDrop(item));
  }

  onBtnUpdatePickWindow(item: ShipmentQueueShipment) {
    this.updateDeliveryWindow(item, this.getPick(item));
  }

  onBtnUpdateDropWindow(item: ShipmentQueueShipment) {
    this.updateDeliveryWindow(item, this.getDrop(item));
  }

  private openTransitManagement(shipment: ShipmentQueueShipment) {
    this.nzDrawerService.create({
      nzTitle: 'Transit Network Management',
      nzSize: 'large',
      nzPlacement: 'right',
      nzContent: TransitNetwork,
      nzContentParams: {
        id: shipment.id,
        showOrderDetail: true,
        compact: true
      },
      nzWidth: 'calc(100vw - 280px)'
    });
  }

  private openReadyForRoutingConfirmation(shipment: ShipmentQueueShipment) {
    this.modalService.confirm({
      nzTitle: "Ready for Routing",
      nzContent: "Please confirm you want to mark this shipment as ready for routing",
      nzOnOk: () => {
        const service = new PlanningService(this.api)
        console.log(shipment.id)
        service.addShipmentToPlanning(shipment.id)?.subscribe((res) => {
          this.showInfo(`Shipment has been added to planning session.`)
        }, (err) => {
          this.showErr(`Error while adding shipment ${shipment.id} to planning session!`)
        })
      }
    }, 'confirm')
  }

  openUpdateShipmentReviewStatus(shipment) {
    this.modalService.create({
        nzTitle: `Update Review Status for ${this.showShipmentOnlyCode(shipment)}`,
        nzContent: UpdateShipmentReviewStatusForm,
        nzWidth: "800px",
        nzComponentParams: {
            id: shipment.id,
            warpId: shipment.warpId,
            code:shipment.code,
            status: shipment.review?.status || {}
        },
        nzOnOk: (comp) => {
            comp.onSave().subscribe((res) => {
                shipment.review = Object.assign({}, shipment.review, {status: comp._status})
                this.onBtnRefresh()
            }, (err) => {
                this.showErr(err)
            })
        },
        nzOnCancel: (comp) => {
            comp.onCancel()
        }
    })
  }

  private openOrderDetail(shipment: ShipmentQueueShipment) {
    this.nzDrawerService.create({
      nzTitle: 'Transit Network Management',
      nzSize: 'large',
      nzPlacement: 'right',
      nzContent: ShipmentEntryDetail,
      nzContentParams: {
        orderId: shipment.orderId,
      },
      nzWidth: 'calc(100vw - 280px)'
    });
  }

  private openOrderfulDialog(item: ShipmentQueueShipment) {
    DialogService.openFormDialog1(OrderfulInfoDialog, {
      nzClassName: 'modal-no-padding',
      nzCentered: true,
      nzComponentParams: {
        order: item.order
      }
    });
  }

  previousItemId: string;
  currentItemId: string;
  currentItem: any;

  handleBoxInfoStyle(item){
    const boxInfoSelectedClass = "box-info-selected"
    this.previousItemId = this.currentItemId
    this.currentItemId = item.id

    const preElm = document.getElementById(this.previousItemId)
    preElm?.classList?.remove(boxInfoSelectedClass)

    const currentElm = document.getElementById(this.currentItemId)
    currentElm?.classList?.add(boxInfoSelectedClass)
  }

  public scheduleAppointment(item){
   this.handleBoxInfoStyle(item)
   this.currentItem = item
  }

}