
import { Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import { Const } from "@const/Const";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { DateUtil } from "@services/date-utils";
import dayjs from "dayjs";

@Component({
  selector: "history-dialog",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})
export class HistoryDialog extends BaseFormDialog1 implements OnInit {

  private _history: [];
  private _timezone: any;
  items: any = {};

  @Input() get timezone() {
    return this._timezone;
  };
  set timezone(value) {
    this._timezone = value;
  }

  @Input() get history() {
    return this._history
  };
  set history(value) {
    this._history = value;
    if (value) {
      this.items = this.formatData(value);
    }
  }
  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  formatData(data) {
    let items = {};
    for (let item of data) {
      let date = item.insert?.when;
      item['time'] = DateUtil.dateToString(date, Const.FORMAT_GUI_DATETIME)
      date = DateUtil.dateToString(date, Const.FORMAT_GUI_DATE);
      if (!items[date]) items[date] = [];
      items[date].push(item);
    }
    return items;
  }

  getContentHis(item) {
    return this.convertObjectToTree(JSON.parse(item.details));
  }

  convertObjectToTree(data, path = ""){
    let result = []
    if(this.isValue(data)){
      return data != null? data : null
    }
    if(this.isArrayValue(data)){
      return data.join(", ")
    }
    const keys = Object.keys(data)
    for(let key of keys){
      let node={} as any
      node.title = this.showKey(key);
      node.expanded = true;
      let childPath = path? `${path}.${key}`: key
      let value = this.convertObjectToTree(data[key], childPath)
      //gán thuộc tính phục vụ cho css
      if(key === "Old value" || key === "Old Value"){
        node.isOld = true
      }
      
      if(key === "New value" || key === "New Value"){
        node.isNew = true
      }
      //nếu null thì kg hiện UI => tránh rác
      if(!["Old value", "New value"].includes(key) && value == null){
        continue
      }
      if(this.isValue(value)){
        if(this.isIsoDate(value)){
          value = DateUtil.displayLocalTime(value, {timezone: this.timezone, format: Const.FORMAT_GUI_DATETIME_V3});
        }
        node.title = `${key}: ${(value == null ||  value == "")? "[NO DATA]" : value}`
        node.isLeaf = true 
      } else {
        node.children = value
      } 
      
      result.push(node);
    }
    return result.length? result : null;
  }

  showKey(key) {
    switch (key) {
      case 'Type': return 'Appointment Type';
      case 'ContactType': return 'Contact Type';
      case 'From': return 'Time from';
      case 'To': return 'Time to';
      default: return key;
    }
  }

  isValue(x){
    return (!this.isObject(x) && !this.isArray(x)) || x == null || x instanceof Date
  }
  isObject(variable) {
    return (typeof variable === 'object' || variable instanceof Object) && !(variable instanceof Date);
  }
  isArray(variable) {
    return Array.isArray(variable);
  }
  isArrayValue (data: any){ 
    if(!Array.isArray(data)){
      return false
    };
    for(let item of data){
      if(!this.isValue(item)){
        return false;
      }
    };
    return true
  }
  isIsoDate(str){
    if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
    const d = new Date(str); 
    return d instanceof Date && !isNaN(d as any) && d.toISOString()===str; // valid date 
  }

  displayWindows(window) {
    if (window && this.timezone) {
      return DateUtil.displayTimeWindow(window, {
        timezone: this.timezone,
        formatDateOnly: 'MM/DD/YY',
        format: "MM/DD/YY h:mm:ss A",
      })
    };
    return 'N/A';
  }

  getDateItems() {
    return Object.keys(this.items);
  }

  onClose() {
    this.closeDialog();
  }
}
