<div *ngIf="!isLoading">
  <div *ngIf="!isShowEmptyView" class="bg-header">
    <div style="display: flex; align-items: baseline;">
      <div class="title">{{getTypeStops(model.type)}} Appointment</div>
      <div class="left20">{{getAddressText(model.addr)}}</div>
      <a class="left20" *ngIf="this.model.histories?.length > 0" (click)="onHisUpdateDetail()">
        <span nz-icon nzType="history" nzTheme="outline" style="margin-right: 4px;"></span>History
      </a>
    </div>
    <div *ngIf="this.model.warehouse"><a (click)="onClickLocationDetail()">Location Details</a></div>
  </div>
  
  <form *ngIf="!isShowEmptyView" [formGroup]="formInput" nz-form>
    <div class="bg-schedule">
  
      <div *ngIf="!contactType">
        <div style="margin-bottom: 20px;">
          <img src="assets/img/shipment-queue/step-1-icon.svg" /> Select Contact Method
        </div>
        <nz-radio-group formControlName="contactType" nzButtonStyle="solid">
          <label (click)="onContactTypeChange(type)" *ngFor="let type of appointmentContactTypeArray" nz-radio-button
            [nzValue]="type">
            {{getAppointmentContactTypeLabel(type)}}
          </label>
        </nz-radio-group>
      </div>
  
      <div *ngIf="contactType">
        <div style="margin-bottom: 20px;" class="contact-type-container">
          <div>
            <img src="assets/img/shipment-queue/check-contact-type-icon.svg" /> Contact Method:
            <b>{{getAppointmentContactTypeLabel(contactType)}}</b>
            <!-- <div *ngIf="isShowLastSentEmail" style="margin-top: 15px;">
              <span nz-typography nzType="secondary">
                Last sent at {{getLastRequestTime()}}
              </span>
            </div> -->
          </div>
          <a (click)="editContactType()">Edit</a>
        </div>
  
        <div class="schedule-appointment">
  
          <div class="schedule-appointment-header">
            <div class="title"> <img src="assets/img/shipment-queue/step-2-icon.svg" /> Schedule Appointment</div>
            <a (click)="openViewNote()"><span class="right10" nz-icon nzType="edit" nzTheme="outline"></span>Add & View Notes</a>
          </div>
  
          <div *ngIf="isShowContactInfo" class="bg-content" style="width: 80%;">
            <div class="content" style="margin-bottom: 10px;">Contact to schedule appointment:</div>
  
            <div *ngFor="let contact of this.model?.contacts || []">
              <ng-container [ngTemplateOutlet]="contactInfo" [ngTemplateOutletContext]="{contact}">
              </ng-container>
            </div>
  
            <div *ngIf="!this.model?.contacts?.length && this.model?.warehouse">
              <ng-container [ngTemplateOutlet]="contactInfo" [ngTemplateOutletContext]="{contact: this.model?.warehouse}">
              </ng-container>
            </div>
  
            <div *ngFor="let window of model?.windows || []" class="content-italic top20">
              <span nz-icon nzType="info-circle" nzTheme="outline" style="margin-right: 4px;"></span>
              Intended Time to Schedule: {{displayWindows(window, model) || 'N/A'}}
            </div>
          </div>
  
          <div *ngIf="isShowNoContactFound" class="bg-content" style="width: 80%; background-color: #ffe58f;">
            <div class="content" style="margin-bottom: 10px;">No contact found</div>
          </div>
  
          <button *ngIf="isShowRequestSentBtn && contactType=='phone'" (click)="confirmRequestSent()" style="margin-top: 15px" nz-button
            nzType="primary">
            <span nz-icon nzType="check" nzTheme="outline"></span>{{requestBtnText}}
          </button>

          <ng-container *ngIf="isEmailContact" [ngTemplateOutlet]="emailContactType"></ng-container>
          <ng-container *ngIf="isSmsContact" [ngTemplateOutlet]="smsContactType"></ng-container>
          
          <div *ngIf="isShowScheduleInput">
            <ng-container [ngTemplateOutlet]="tplTimeWindows"
              [ngTemplateOutletContext]="{key: appointmentWindowFormName, form: formInput}">
            </ng-container>
  
            <div style="margin-bottom: 5px;">{{getLabel('type')}}<span *ngIf="isRequired('type')"
                class="label-mark-required"></span></div>
            <nz-form-item style="width: 420px;">
              <nz-form-control>
                <nz-select (ngModelChange)="isModelChanged=true" formControlName="type">
                  <nz-option *ngFor="let type of appointmentTypeArray" [nzLabel]="getAppointmentTypeLabel(type)"
                    [nzValue]="type">
                  </nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
            <button nz-button (click)="onBtnScheduleAppointment()" [disabled]="!isAllowSubmitScheduleAppt"
              nzType="primary" class="top50">
              Schedule Appointment
            </button>
  
            <div style="margin-top: 20px;" *ngIf="this.model.histories?.length > 0">
              <span nz-typography nzType="secondary">
                Last updated on <b>{{getLastUpdateTime()}}</b> by <b>{{getLastUpdateUser()}}</b>
              </span>
            </div>
  
          </div>

        </div>
      </div>
    </div>
  </form>
  
  <div *ngIf="isShowEmptyView" class="bg-schedule">
    <div style="background: white; min-height: 430px; padding: 130px;">
      <nz-empty [nzNotFoundContent]="'Select a Pickup/Drop-off Location'"></nz-empty>
    </div>
  </div>

  <ng-template #emailContactType>
    <div [formGroup]="formInput" *ngIf="isShowSendMail" class="box-with-title" style="margin-top: 15px; width: 80%;" >
      <div class="box-title">Mail to book an appointment</div>
      <div class="box-content">
        <div nz-row [nzGutter]="12">
          <div nz-col nzSpan="24">
            <i>Email (with a link to enter appointment) will be sent to the emails below. <br/>
              Please select the emails you would like to send:
            </i>
          </div>
          <div nz-col nzSpan="24">
            <nz-form-item style="margin-top: 15px">
              <nz-checkbox-group
                formControlName="emails"
                [ngModel]="checkboxOptions"
              ></nz-checkbox-group>
            </nz-form-item>
  
            <button [disabled]="!isAllowSendMail" (click)="onBtnSendMail()" nz-button
              nzType="primary">
              <span nz-icon nzType="send" nzTheme="outline"></span> Send email
            </button>
          </div>
        </div>
      </div>
    </div>
  
    <div *ngIf="isShowCannotSendMail" class="box-with-title" style="margin-top: 15px; width: 80%;" >
      <div class="box-title">Mail to book an appointment</div>
      <div class="box-content">
        <div nz-row [nzGutter]="12">
          <div class="bg-content" style="width: 100%; background-color: #ffe58f;">
            <div class="content">
              We could not find any email to contact. Please use other methods or <a (click)="onBtnAddContact()">update the contact</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <div *ngIf="isShowLastSentEmail" class="bg-content" style="width: 80%; background-color: #ffe58f;">
      <div class="content">
        <span nz-typography>
          <span nz-icon nzType="send" nzTheme="outline"></span> Last sent email at <b>{{getLastRequestTime()}}</b> <br/>
          Please wait for a response or you can still enter the appointment below if you want to manually update this.
        </span>
      </div>
    </div>
    <div *ngIf="isShowLastSentSms" class="bg-content" style="width: 80%; background-color: #ffe58f;">
      <div class="content">
        <span nz-typography>
          <span nz-icon nzType="send" nzTheme="outline"></span> Last sent sms at <b>{{getLastRequestTime()}}</b> <br/>
          Please wait for a response or you can still enter the appointment below if you want to manually update this.
        </span>
      </div>
    </div>
  </ng-template>

  <ng-template #smsContactType>
    <div [formGroup]="formInput" *ngIf="isShowSendSms" class="box-with-title" style="margin-top: 15px; width: 80%;" >
      <div class="box-title">Send SMS to book an appointment</div>
      <div class="box-content">
        <div nz-row [nzGutter]="12">
          <div nz-col nzSpan="24">
            <i>SMS (with a link to enter appointment) will be sent to the phone below. <br/>
              Please select phone number you would like to send:
            </i>
          </div>
          <div nz-col nzSpan="24">
            <nz-form-item style="margin-top: 15px">
              <nz-checkbox-group
                formControlName="phoneNumbers"
                [ngModel]="phoneNumberOptions"
              ></nz-checkbox-group>
            </nz-form-item>
  
            <button [disabled]="!isAllowSendSms" (click)="onBtnSendSMS()" nz-button
              nzType="primary">
              <span nz-icon nzType="send" nzTheme="outline"></span> Send SMS
            </button>
          </div>
        </div>
      </div>
    </div>
  
    <div *ngIf="isShowCannotSendSms" class="box-with-title" style="margin-top: 15px; width: 80%;" >
      <div class="box-title">Send SMS to book an appointment</div>
      <div class="box-content">
        <div nz-row [nzGutter]="12">
          <div class="bg-content" style="width: 100%; background-color: #ffe58f;">
            <div class="content">We could not find any phone number to send sms. Please use other methods or <a (click)="onBtnAddContact()">update the contact</a></div>
          </div>
        </div>
      </div>
    </div>
  
    <div *ngIf="isShowLastSentSms" class="bg-content" style="width: 80%; background-color: #ffe58f;">
      <div class="content">
        <span nz-typography>
          <span nz-icon nzType="send" nzTheme="outline"></span> Last sent sms at <b>{{getLastRequestTime()}}</b> <br/>
          Please wait for a response or you can still enter the appointment below if you want to manually update this.
        </span>
      </div>
    </div>
  </ng-template>
  
  <ng-template #contactInfo let-contact='contact'>
    <div *ngIf="contact.name || contact.contactName" class="content"><span nz-icon nzType="user" nzTheme="outline"
        style="margin-right: 4px;"></span>Name: {{contact.name || contact.fullName || contact.contactName}}</div>
    <div>
      <span nz-icon nzType="phone" nzTheme="outline" style="margin-right: 4px;"></span>
      Phone: <a *ngIf="contact.phone">{{contact.phone}}</a> <span *ngIf="!contact.phone">N/A</span>
      <i *ngIf="contact.phone" nz-icon nzType="copy" nzTheme="outline" class="clickable"
        (click)="copyPhoneNumber(contact.phone)" nz-tooltip nzTooltipTitle="Copy phone number"
        style="margin-left: 5px;"></i>
    </div>
    <div class="content">
      <span nz-icon nzType="mail" nzTheme="outline"></span>
      Email: {{contact.email || 'N/A'}}
      <i *ngIf="contact.email" nz-icon nzType="copy" nzTheme="outline" class="clickable"
        (click)="copyEmail(contact.email)" nz-tooltip nzTooltipTitle="Copy email" style="margin-left: 5px;">
      </i>
    </div>
    <nz-divider></nz-divider>
  </ng-template>
  
  <ng-template #tplTimeWindows let-key="key" let-form="form">
    <ng-container [formGroup]="form">
      <ng-container [formArrayName]="key">
        <form style="display: flex; margin-top: 25px;" *ngFor="let item of getControlTimeWindows(); let arrIndex = index"
          nz-form [formGroupName]="arrIndex">
          <div style="display: flex;">
            <div *ngFor="let childKey of ['date', 'fromTime', 'toTime']; let i = index"
              [ngClass]="{'flex1': i == 0, 'right20': i == 0}">
              <div class="form-label-v2 single-line fix-height">
                {{getLabel(fullKey(key, childKey))}}
                <span *ngIf="isRequired(fullKey(key, childKey))" class="label-mark-required"></span>
              </div>
              <nz-form-item>
                <nz-form-control>
                  <nz-date-picker (ngModelChange)="isModelChanged=true" *ngIf="childKey == 'date'" [nzAllowClear]="false"
                    [formControlName]="childKey" nzFormat="yyyy-MM-dd"></nz-date-picker>
                  <nz-time-picker [nzMinuteStep]="15" (ngModelChange)="isModelChanged=true" *ngIf="childKey != 'date'"
                    [formControlName]="childKey" nzFormat="HH:mm" [nzPlaceHolder]="getPlaceHolder(fullKey(key, childKey))"
                    [ngClass]="childKey" [nzAllowEmpty]="false">
                  </nz-time-picker>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </form>
      </ng-container>
    </ng-container>
  </ng-template>
</div>


<div *ngIf="isLoading" class="bg-schedule">
  <div style="background: white; min-height: 430px; padding: 130px;">
    <nz-skeleton [nzActive]="isLoading"></nz-skeleton>
  </div>
</div>
