import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FilterLayout, FilterFieldDeclaration } from "@app/admin/components/filter-layout";
import { Const } from "@const/Const";
import { BizUtil } from "@services/biz";
import { DateUtil } from "@services/date-utils";
import { MasterData } from "@services/master.data";

@Component({
  selector: '[shipment-queue-filter-need-routing]',
  templateUrl: './need-routing.html',
  styleUrls: ['./style.scss']
})
export class ShipmentQueueFilterNeedRouting implements OnInit, AfterViewInit {

  public allStates = MasterData.getStatesUS();
  public allShipmentTypes = [
    Const.ShipmentTypes.fullTruckLoad,
    Const.ShipmentTypes.lessThanTruckload,
    Const.ShipmentTypes.parcel
  ];
  public countriesStates = MasterData.getCountriesStates_forSelectGroup();

  constructor() {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.filter.ngHostAfterViewInit(this.getFilterFields())
  }

  public getShipmentTypeName(code: string): string {
    return BizUtil.getShipmentTypeName(code);
  }

  public getStateDesc(state): string {
    let obj = state;
    if (typeof state == 'string') {
      obj = MasterData.getStateUSByCode(state);
    }
    if (!obj) {
      return state;
    }
    let str = obj.code;
    if (obj.name) {
      str += ` (${obj.name})`;
    }
    return str;
  }

  // for filter layout
  @ViewChild('filter') filter: FilterLayout;
  @ViewChild('tplFilter_pickState') tplFilter_pickState: TemplateRef<any>;
  @ViewChild('tplFilter_customer') tplFilter_customer: TemplateRef<any>;
  @ViewChild('tplFilter_date') tplFilter_date: TemplateRef<any>;
  @ViewChild('tplFilter_shipmentType') tplFilter_shipmentType: TemplateRef<any>;

  onFilterChange(key: string, value: any) {
    this.filter.onChange({key, value});
  }

  getFilterPlaceHolder(key: string): string {
    return <string>this.filter.getPlaceHolder(key);
  }

  getFilterFields(): FilterFieldDeclaration[] {
    return [
      {
        key: 'pickState',
        declaration: { label: 'Pickup State', notAcceptEmpty: true, placeHolder: '' },
        templateInput: this.tplFilter_pickState
      },
      {
        key: 'dropState',
        declaration: { label: 'Delivery State', notAcceptEmpty: true, placeHolder: '' },
        templateInput: this.tplFilter_pickState
      },
      {
        key: 'shipmentType',
        declaration: { label: 'Shipment Type', notAcceptEmpty: true, placeHolder: '' },
        templateInput: this.tplFilter_shipmentType
      },
      {
        key: 'clientId',
        declaration: { label: 'Customer', placeHolder: '', notAcceptEmpty: true },
        templateInput: this.tplFilter_customer
      },
      {
        key: 'pickFrom',
        declaration: { label: 'Pickup from date', placeHolder: '', notAcceptEmpty: true, getValue: DateUtil.getDateFrom },
        templateInput: this.tplFilter_date
      },
      {
        key: 'pickTo',
        declaration: { label: 'Pickup to date', placeHolder: '', notAcceptEmpty: true, getValue: DateUtil.getDateTo },
        templateInput: this.tplFilter_date
      },
    ]
  }

  getFilterData() {
    return this.filter.formData_JSON(true);
  }

}
