
<div style="display: flex; width: 100%; justify-content: space-between; margin-bottom: 12px;">
  <div class="title">Notes</div>
  <span nz-icon nzType="close" style="cursor: pointer;" nzTheme="outline" (click)="onClose()"></span>
</div>
<div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>

<div class="note-list" *ngIf="!isLoading">
  <div class="group-date bottom20" *ngFor="let date of getDateItems()">
    <div class="group-content">
      <ng-container *ngFor="let item of items[date]" [ngTemplateOutlet]="noteItem"
        [ngTemplateOutletContext]="{item, edittingId}"></ng-container>
    </div>
  </div>
</div>

<ng-container [ngTemplateOutlet]="noteForm" *ngIf="edittingId ==-1"
  [ngTemplateOutletContext]="{btnOk: 'Submit', onSubmit: onAdd, onCancel: close}"></ng-container>

<ng-template #noteForm let-onCancel="onCancel" let-onSubmit="onSubmit" let-edittingId="edittingId">
  <div class="create-form" style="display: flex; align-items: center;">
    <textarea nz-input class="input-note" placeholder="Add a note..." [(ngModel)]="newNoteMsg" [disabled]="isLoading"></textarea>
    <nz-space nzSize="small" class="note-button-group">
      <ng-container *ngIf="shouldShowBtnCancel" >
        <button *nzSpaceItem nz-button (click)="onCancel()">Cancel</button>
      </ng-container>
      <button *nzSpaceItem nz-button nzType="primary" (click)="onSubmit()" [disabled]="!shouldShowBtnAddNote">{{getLabelBtnAdd()}}</button>
    </nz-space>
  </div>
</ng-template>

<ng-template #noteItem let-item="item" let-edittingId="edittingId">
  <div class="note-list-item">
    <div class="item-header">
      <div class="poster">
        <nz-avatar [nzText]="getFullName(item.user).slice(0,1)" [ngStyle]="{ 'background-color': '#7265e6' }"></nz-avatar>
        <div class="info">
          <div class="name semibold">
            {{getFullName(item.user) || 'No name'}}
            <span *ngIf="item.pinned?.when" nz-icon nzType="pushpin" nzTheme="fill" class="left10" style="color: #FAAD14;"></span>
          </div>
          <div class="time">{{item.time}}</div>
          <div class="top" style="width: 100%; margin-top: 5px;">
            <div style="line-height: 22px;word-wrap: break-word;" *ngIf="item.content && edittingId !== item.id" [innerText]="item.content"></div>
            <ng-container [ngTemplateOutlet]="noteForm" *ngIf="edittingId == item.id"
              [ngTemplateOutletContext]="{onSubmit: onSubmitEdit, onCancel: onCancelEdit, edittingId}"></ng-container>
          </div>
        </div>
      </div>
      <div class="menu-header">
        <i nz-icon nzType="edit" nzTheme="outline" class="clickable" nz-popover (click)="onEdit(item)"></i>
      </div>
    </div>
    <div class="item-body top20">
      
    </div>
  </div>
</ng-template>
