
<div style="font-size: 16px; font-weight: 500;">
  <i nz-icon nzType="close" nzTheme="outline" style="cursor: pointer; margin-bottom: 24px;" (click)="onCloseClick()" ></i>
  Notes
</div>

<ng-container [ngTemplateOutlet]="noteForm" *ngIf="edittingId ==-1"
[ngTemplateOutletContext]="{btnOk: 'Submit', onSubmit: onAdd, onCancel: close}"></ng-container>

<div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>

<div class="note-list" *ngIf="!isLoading">
<div *ngIf="pinnedItems.length > 0" class="pinned-list">
  <div class="bottom5 flex">
    <div class="label">
      <span nz-icon nzType="pushpin" nzTheme="outline"></span>
      <span class="f13 left5">{{pinnedItems.length}} Pinned</span>
    </div>
  </div>
  <div class="group-content">
    <ng-container *ngFor="let item of pinnedItems" [ngTemplateOutlet]="noteItem"
      [ngTemplateOutletContext]="{item, edittingId}"></ng-container>
  </div>
</div>
<div class="group-date bottom20" *ngFor="let date of getDateItems()">
  <div class="date-label">{{date}}</div>
  <div class="group-content">
    <ng-container *ngFor="let item of items[date]" [ngTemplateOutlet]="noteItem"
      [ngTemplateOutletContext]="{item, edittingId}"></ng-container>
  </div>
</div>
</div>

<ng-template #noteForm let-onCancel="onCancel" let-onSubmit="onSubmit" let-edittingId="edittingId">
<div class="create-form">
  <textarea nz-input class="input-note" placeholder="Add a note..." [(ngModel)]="newNoteMsg"
    [nzAutosize]="{ minRows: 2, maxRows: 6 }" [disabled]="isLoading"></textarea>
    <ng-container *ngIf="!edittingId">
      <form-input-upload-images #uploadImagesForm [visible]="!isLoading" (fileSelectedChange)="onFileImageSelectedChange($event)"></form-input-upload-images>
    </ng-container>
  <nz-space nzSize="small" class="note-button-group">
    <ng-container *ngIf="shouldShowBtnCancel" >
      <button *nzSpaceItem nz-button (click)="onCancel()">Cancel</button>
    </ng-container>
    <button *nzSpaceItem nz-button nzType="primary" (click)="onSubmit()" [disabled]="!shouldShowBtnAddNote">{{getLabelBtnAdd()}}</button>
  </nz-space>
</div>
</ng-template>

<ng-template #noteItem let-item="item" let-edittingId="edittingId">
<div class="note-list-item">
  <div class="item-header">
    <div class="poster">
      <ng-container *ngIf="isExfreight(item)">
        <nz-avatar nzIcon="user" nzSrc="assets/img/exfreight.png"></nz-avatar>
      </ng-container>
      <ng-container *ngIf="!isExfreight(item)">
        <nz-avatar [nzText]="getCarrierName(item.carrier)?.slice(0,1) || getFullName(item.user).slice(0,1)" [ngStyle]="{ 'background-color': '#7265e6' }"></nz-avatar>
      </ng-container>
      <div class="info">
        <div class="name semibold">
          {{getCarrierName(item?.carrier) || getFullName(item.user) || 'No name'}}
          <span *ngIf="item.pinned?.when" nz-icon nzType="pushpin" nzTheme="fill" class="left10" style="color: #FAAD14;"></span>
        </div>
        <div class="time">{{item.time}}</div>
        <div class="top" style="width: 100%; margin-top: 5px;">
          <div style="line-height: 22px;word-wrap: break-word;" *ngIf="item.content && edittingId !== item.id" [innerText]="item.content"></div>
          <div *ngIf="item?.imageUploadFilesArr" class="list-images-upload">
            <div *ngFor="let image of item?.imageUploadFilesArr;let i = index">
              <img *ngIf="image" (click)="viewImageItem(image.imgUrl)" class="attached-image" [src]="image.imgUrl" alt="">
            </div>
          </div>
          <ng-container [ngTemplateOutlet]="noteForm" *ngIf="edittingId == item.id"
            [ngTemplateOutletContext]="{onSubmit: onSubmitEdit, onCancel: onCancelEdit, edittingId}"></ng-container>
        </div>
      </div>
    </div>
    <div class="menu-header">
      <i nz-icon nzType="more" nzTheme="outline" class="clickable" [nzPopoverContent]="moreActions" nz-popover></i>
      <ng-template #moreActions>
        <div class="more-actions-note">
          <div *ngIf="!item.pinned?.when" class="action" (click)="onBtnPin(item)">Pin this note</div>
          <div *ngIf="item.pinned?.when" class="action" (click)="onBtnUnPin(item)">Un-pin this note</div>
          <div class="action" (click)="onEdit(item)">Edit message</div>
        </div>
      </ng-template>
      <!-- <i nz-icon nzType="edit" class="edit-btn" nzTheme="outline" (click)="onEdit(item)"></i> -->
    </div>
  </div>
  <div class="item-body top20">
    
  </div>
</div>
</ng-template>

