import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { BaseFormItem } from "@app/admin/base/form-item";
import { DialogService } from "@dialogs/dialog.service";
import { DateUtil } from "@services/date-utils";
import { Utils } from "@services/utils";
import { ImageUtil } from "@services/image-util";

@Component({
  selector: "form-input-upload-images",
  templateUrl: "./comp.html",
  styleUrls: ["./style.scss"],
})
export class FormInputUploadImages extends BaseFormItem implements OnInit {
  @Input() visible;

  @Output() fileSelectedChange = new EventEmitter();

  isLoading: boolean = true;
  isUploading: boolean = false;
  isError: boolean = false;
  constructor() {
    super();
  }
  selectedFiles = [];
  previews = [];

  ngOnInit(): void {}

  public resetFormInput() {
    this.selectedFiles = [];
    this.previews = [];
  }

  onFileSelected(inputElement: HTMLInputElement) {
    let file = inputElement.files[0];
    inputElement.value = "";
    ImageUtil.resizeImageIfNeeded(file).then((blob) =>
      {
        const obj = {
          file : blob,
          fileName: file.name
        }
        this.selectedFiles.push(obj);
        const reader = new FileReader();
        reader.onload = (event) => {
          this.previews.push(<string>(<FileReader>event.target).result);
        }
        reader.readAsDataURL(blob);
        this.fileSelectedChange.emit(this.selectedFiles);
      }
    );
  }

  onBtnDelImage(index) {
    for (let i=index; i < this.selectedFiles.length - 1; i++) {
      let tmp = this.selectedFiles[i];
      this.selectedFiles[i] = this.selectedFiles[i+1];
      this.selectedFiles[i+1] = tmp;
    }
    this.selectedFiles.pop();

    for (let i=index; i < this.previews.length - 1; i++) {
      let tmp = this.previews[i];
      this.previews[i] = this.previews[i+1];
      this.previews[i+1] = tmp;
    }
    this.previews.pop();
    this.fileSelectedChange.emit(this.selectedFiles);
  }

  viewImageItem(imgUrl) {
    if (!imgUrl) return;
    DialogService.previewImgs([imgUrl], 0);
  }

}
