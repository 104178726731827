
<div style="display: flex; width: 100%; justify-content: space-between; margin-bottom: 12px;">
  <div class="title">Appointment History</div>
  <span nz-icon nzType="close" style="cursor: pointer;" nzTheme="outline" (click)="onClose()"></span>
</div>

<div class="note-list">
  <div class="group-date bottom20" *ngFor="let date of getDateItems()">
    <div class="group-content">
      <ng-container *ngFor="let item of items[date]" [ngTemplateOutlet]="noteItem"
        [ngTemplateOutletContext]="{item}"></ng-container>
    </div>
  </div>
</div>

<ng-template #noteItem let-item="item">
  <div class="note-list-item">
    <div class="item-header">
      <div class="poster">
        <nz-avatar [nzText]="getFullName(item.metadata?.user).slice(0,1)" [ngStyle]="{ 'background-color': '#7265e6' }"></nz-avatar>
        <div class="info">
          <div class="name semibold">
            {{getFullName(item.metadata?.user) || 'No name'}}
          </div>
          <div class="time">{{item.time}}</div>
          <div class="top" style="width: 100%; margin-top: 5px;">
            <nz-tree [nzData]="getContentHis(item)" nzDraggable nzBlockNode
            [nzTreeTemplate]="nzTreeTemplate"></nz-tree>
          </div>
        </div>
      </div>
    </div>
    <div class="item-body top20">
      
    </div>
  </div>
</ng-template>

<ng-template #nzTreeTemplate let-node let-origin="origin">
  <span class="custom-node">
    <span *ngIf="origin.isOld">
      <span class="old-value">{{ node.title }}</span>
    </span>
    <span *ngIf="origin.isNew">
      <span class="new-value">{{ node.title }}</span>
    </span>
    <span *ngIf="!origin.isNew && !origin.isOld">
      <span> {{ node.title }}</span>
    </span>
  </span>
</ng-template>
