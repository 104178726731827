<div #filter filter-layout></div>

<!-- templates for filter fields -->
<ng-template #tplFilter_pickState let-key="key" let-form="form">
  <ng-container [formGroup]="form">
    <form-filter-states [formControlName]="key"
      [placeHolder]="'Select'"
      [countriesStates]="countriesStates"
      (ngModelChange)="onFilterChange(key, $event)"></form-filter-states>
  </ng-container>
</ng-template>

<ng-template #tplFilter_customer let-key="key" let-form="form">
  <ng-container [formGroup]="form">
    <select-by-searching
      [version]="2"
      nzMode="multiple"
      [formControlName]="key" (modelChange)="onFilterChange(key, $event)"
      [placeholder]="'Select'" dropdownClassName="filter-customer">
    </select-by-searching>
  </ng-container>
</ng-template>

<ng-template #tplFilter_date let-key="key" let-form="form">
  <ng-container [formGroup]="form">
    <nz-date-picker [formControlName]="key" (ngModelChange)="onFilterChange(key, $event)"></nz-date-picker>
  </ng-container>
</ng-template>

<ng-template #tplFilter_shipmentType let-key="key" let-form="form">
  <ng-container [formGroup]="form">
    <nz-select nzAllowClear nzShowSearch style="width: 100%;"
      [formControlName]="key"
      [nzDropdownClassName]="'filter-shipmentType'"
      [nzPlaceHolder]="'Select'"
      (ngModelChange)="onFilterChange(key, $event)">
      <nz-option *ngFor="let type of allShipmentTypes" [nzLabel]="getShipmentTypeName(type)" [nzValue]="type"></nz-option>
    </nz-select>
  </ng-container>
</ng-template>