import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { ShipmentQueueListNeedAppointments } from '.';
import { ModuleBookAppointment, ModuleUpdateTimeWindows } from '@wearewarp/ng-antd';
import { OrderfulDialogModule } from '@app/admin/shipments/orderful-info/module';
import { TransitNetworkModule } from '@app/admin/transit-network/module';
import { ShipmentQueueFilterModule } from '../filter/module';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { ScheduleAppointments } from './schedule-appointments';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DrawerModule } from '@app/drawers/drawer.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NotesDialog } from './notes';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { HistoryDialog } from './history';
import { NzTreeModule } from 'ng-zorro-antd/tree';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NotesForm } from './notes-v2';
import { FormInputUploadImagesModule } from '@app/admin/base/form-input-upload-images/module';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { DetailModule } from '@app/admin/base/detail.module';
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NzTableModule,
    NzIconModule,
    NzToolTipModule,
    ModuleBookAppointment,
    ModuleUpdateTimeWindows,
    OrderfulDialogModule,
    TransitNetworkModule,
    ShipmentQueueFilterModule,
    NzGridModule,
    NzDividerModule,
    NzSpaceModule,
    NzAvatarModule,
    NzDatePickerModule,
    NzTimePickerModule,
    NzFormModule,
    FormsModule,
    NzButtonModule,
    NzRadioModule,
    ScrollingModule,
    DrawerModule,
    ReactiveFormsModule,
    NzEmptyModule,
    NzSelectModule,
    NzTreeModule,
    NzTypographyModule,
    NzSkeletonModule,
    NzInputModule,
    FormInputUploadImagesModule,
    NzInputModule,
    NzPopoverModule,
    NzCheckboxModule,
    DetailModule
  ],
  declarations: [
    ShipmentQueueListNeedAppointments,
    ScheduleAppointments,
    NotesDialog,
    HistoryDialog,
    NotesForm
  ],
  exports: [
    ShipmentQueueListNeedAppointments,
  ]
})
export class ShipmentQueueV2ListNeedAppointmentsModule {}