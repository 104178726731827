import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { ShipmentQueueListShipments } from '.';
import { ModuleBookAppointment, ModuleUpdateTimeWindows } from '@wearewarp/ng-antd';
import { OrderfulDialogModule } from '@app/admin/shipments/orderful-info/module';
import { TransitNetworkModule } from '@app/admin/transit-network/module';
import { ShipmentQueueFilterModule } from '../filter/module';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NzTableModule,
    NzIconModule,
    NzToolTipModule,
    ModuleBookAppointment,
    ModuleUpdateTimeWindows,
    OrderfulDialogModule,
    TransitNetworkModule,
    ShipmentQueueFilterModule,
    NzGridModule,
    NzDividerModule,
    NzSpaceModule,
    NzAvatarModule
  ],
  declarations: [
    ShipmentQueueListShipments,
  ],
  exports: [
    ShipmentQueueListShipments,
  ]
})
export class ShipmentQueueV2ListShipmentsModule {}